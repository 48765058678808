import React, { useCallback, useEffect, useRef, useState } from "react";
import { useStateContext } from "../contexts/ContextProvider";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import { urlBase } from "../authConfig";
import Loading from '../animations/Loading.json'
import { Player } from '@lottiefiles/react-lottie-player';
import { useNavigate } from "react-router-dom";

//const stripePromise = loadStripe('pk_test_51Pr0drI3uw0UqnHM4hfJW96TnAmCQLtl9LqqzZGGSUbHrOedr1BiaCGHNFvhkjdqmYgPU6hHXwowe4qn4zOuRj1e00qrV13Aog');
const stripePromise = loadStripe('pk_live_51Pr0drI3uw0UqnHMrSWPPpPC7Mpadn0ErmT8Y9NxmeBfJyiz7LnqWx1L8OFSqih9TqsPr3WRu7tld3Sy9Vtjc8WA00diG8qpIh');

const Payment = () => {
    const { accessToken, idPriceStripe, idClient } = useStateContext();
    const [clientSecret, setClientSecret] = useState(null);
    const [status, setStatus] = useState('onProgress');

    const navigate = useNavigate();

    const getData = useCallback(async () => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');
        try {
            const response = await fetch(`${urlBase}/PaymentSub`, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    clientID: idClient,
                    idPrice: idPriceStripe
                })
            });
            const data = await response.json();
            console.log(data, 'This is data');
            return data.clientSecret;
        } catch (error) {
            console.log('Error al obtener clientSecret:', error);
        }
    }, [accessToken]);

    useEffect(() => {
        getData().then(clientSecret => {
            if (typeof clientSecret === 'string') {
                setClientSecret(clientSecret);
            } else {
                console.error('Error: clientSecret no es una cadena válida', clientSecret);
            }
        });
    }, [getData]);


    const handleCheckoutCompletition = () => {
        setStatus('success');
        setTimeout(() => {
            navigate('/summary');
        }, 2000);
    }

    const options = clientSecret
        ? { ...{ clientSecret }, onComplete: handleCheckoutCompletition }
        : null;

    return (
        <>
            {options ?
                <>
                    <div className="fixed top-0 left-[21%] bottom-0 w-screen z-[1000] h-screen">
                        <div className="fixed top-0 left-[15%] bottom-0 w-screen z-10 h-screen opacity-50 bg-gray-6"></div>
                        {status === 'onProgress' ? (
                            <div className="z-10 relative sm:mt-7 md:mt-7 lg:mt-7 xl:mt-7 3xl:mt-20 mt-7 max-w-[60%] min-w-[60%] bg-[#3C0383] h-[700px] rounded-main shadow-high-elevation">
                                <div className="flex justify-center w-full h-[80%] bg-[#3C0383] mx-auto rounded-lg">
                                    <div id="checkout" className="w-full mt-10 rounded-lg">
                                        <EmbeddedCheckoutProvider
                                            stripe={stripePromise}
                                            options={options}
                                        >
                                            <EmbeddedCheckout
                                            />
                                        </EmbeddedCheckoutProvider>
                                    </div>
                                </div>
                            </div>
                        ) :
                            status === 'success' ? (
                                <div className="z-10 relative sm:mt-7 md:mt-7 lg:mt-7 xl:mt-7 3xl:mt-20 mt-7 max-w-[60%] min-w-[60%] flex bg-white h-[700px] rounded-main shadow-high-elevation">
                                    <div className="h-full w-[5%] bg-green-500"></div>
                                    <div className="w-[95%] h-full flex flex-col justify-center items-center">
                                        <h2 className="text-green-500 text-3xl font-bold">Pago realizado</h2>
                                        <p className="text-gray-300 mt-2">Tu pago ha sido aplicado exitosamente</p>
                                    </div>
                                </div>
                            ) : (
                            <div className="z-10 relative sm:mt-7 md:mt-7 lg:mt-7 xl:mt-7 3xl:mt-20 mt-7 max-w-[60%] min-w-[60%] flex bg-white h-[700px] rounded-main shadow-high-elevation">
                                <div className="h-full w-[5%] bg-red-500"></div>
                                <div className="w-[95%] h-full flex flex-col justify-center items-center">
                                    <h2 className="text-red-500 text-3xl font-bold">Pago rechazado</h2>
                                    <p className="text-gray-300 mt-2">Ocurrió un error, intenta nuevamente</p>
                                </div>
                            </div>
                            )
                        }
                    </div>
                </>
                :
                <div className='fixed top-0 left-[15%] bottom-0 w-screen z-[1000] h-screen'>
                    <div className='fixed top-0 right-0 left-[15%] bottom-0 w-screen z-10 h-screen opacity-50 bg-neutral-5'></div>
                    <div className='w-full h-full flex justify-center items-center pr-[15%]'>
                        <Player
                            className='w-[20%]'
                            autoplay
                            loop
                            src={Loading}
                        >
                        </Player>
                    </div>
                </div>
            }
        </>
    );
}

export default Payment;
