import React, { useEffect, useState } from 'react'
import { useStateContext } from '../contexts/ContextProvider'
import { useNavigate } from 'react-router-dom';
import { useMsal } from "@azure/msal-react";
import LogOutLogo from '../assets/LogOutLogo';
import MDCashLogo from '../assets/MDCashLogo';
import { Idioma } from '../components';
import ControlCenterLogo from '../assets/ControlCenterLogo';
import { urlBase } from '../authConfig';
import { getToken } from '../fetch';
import NavbarUserLogo from '../assets/NavbarUserLogo';
import DotLogo from '../assets/DotLogo';
import SelectConsolidatedLogo from '../assets/SelectConsolidatedLogo';
import MDCashLogo1 from '../assets/MDCashLogo1';
import ChromeLogo from '../assets/ChromeLogo';

const SelectClientScreen = () => {
    const { idClient, setClient, clientOptions, userInfo, clientRSOptions, setRS, setToken, accessToken, sessionID, setCheckIsPay, setIdPrice } = useStateContext()
    const navigate = useNavigate()
    const [options, setOptions] = useState([])
    const [language, setLanguage] = useState('es')
    const [isAdmin, setIsAdmin] = useState(false)
    const [selectedClient, setSelectedClient] = useState()
    const [selectedClientOptions, setSelectedClientOptions] = useState(clientRSOptions)
    const [firstPageOptions, setFirstPageOptions] = useState([])

    const detectBrowser = () => {
        let userAgent = navigator.userAgent;
        if (userAgent.indexOf("Edg") > -1) {
            return "Microsoft Edge";
        } else if (userAgent.indexOf("Chrome") > -1) {
            return "Chrome";
        } else if (userAgent.indexOf("Firefox") > -1) {
            return "Firefox";
        } else if (userAgent.indexOf("Safari") > -1) {
            return "Safari";
        } else if (userAgent.indexOf("Opera") > -1) {
            return "Opera";
        } else if (userAgent.indexOf("Trident") > -1 || userAgent.indexOf("MSIE") > -1) {
            return "Internet Explorer";
        }

        return "Unknown";
    }

    const browser = detectBrowser()

    useEffect(() => {
        if (clientRSOptions && clientOptions) {
            let aux = [...clientOptions, ...clientRSOptions]
            aux.sort(function (a, b) {
                let keyA = a.client
                let keyB = b.client
                if (keyA > keyB) return 1
                if (keyA < keyB) return -1
                return 0
            })
            aux.sort((a, b) => {
                if(a.rsAlias && b.rsAlias) {
                    let keyA = a.rsAlias;
                    let keyB = b.rsAlias;
                    if(keyA > keyB) return 1;
                    if(keyA < keyB) return -1;
                    return 0;
                }
            })
            setOptions(aux)
            let aux1 = new Set()
            let aux2 = []
            //console.log('aux sorted',aux)
            for (let i = 0; i < aux.length; i++) {
                //console.log('index',i,aux[i])
                if (!aux1.has(aux[i].idClient)) {

                    aux1.add(aux[i].idClient)
                    aux2.push({ client: aux[i].client, idClient: aux[i].idClient, startIndex: i })
                }
            }
            setFirstPageOptions(aux2)
        }

    }, [clientRSOptions, clientOptions])

    const optionSelect = (option) => {
        option.idStripePrice ? setIdPrice(option.idStripePrice) : setIdPrice(null);
        setCheckIsPay(option.paymentValid);
        setClient({
            'id': option.idClient,
            'name': option.client,
            'access1': option.access1,
            'access2': option.access2,
            'access3': option.access3,
            'access4': option.access4,
            'access5': option.access5,
            'access6': option.access6,
            'access7': option.access7,
            'access8': option.access8,
            'access9': option.access9,
            'language': option.language,
            'currency': option.currency,
            'numberFormat': option.numberFormat,
        })
        navigate('/summary')

    }
    const optionRSSelect = (rsOption) => {
        setCheckIsPay(rsOption.paymentValid);
        setRS({
            'id': rsOption.idClient,
            'name': rsOption.client,
            'idRS': rsOption.idRS,
            'rsName': rsOption.rsAlias,
            'access1': rsOption.access1,
            'access2': rsOption.access2,
            'access3': rsOption.access3,
            'access4': rsOption.access4,
            'access5': rsOption.access5,
            'access6': rsOption.access6,
            'access7': rsOption.access7,
            'access8': rsOption.access8,
            'access9': rsOption.access9,
            'language': rsOption.language,
            'currency': rsOption.currency,
            'numberFormat': rsOption.numberFormat,
        })
        navigate('/summary')
    }
    const { instance } = useMsal();
    function handleLogout(instance) {
        instance.logoutPopup().then(() => {
            setClient('')
        })
            .catch(e => {
                console.error(e);
            });
        navigate('/')
    }

    useEffect(() => {
        if (idClient) {
            navigate('/summary')
        }
        let aux = navigator.language || navigator.userLanguage
        if (Idioma.welcomeScreen.texto1[aux]) {
            setLanguage(aux)
        }
        getToken().then((token) => { setToken(token) })

        let headers = new Headers()
        const bearer = `Bearer ${accessToken}`;
        headers.append("Authorization", bearer);
        headers.append("Content-Type", 'application/json')
        fetch(`${urlBase}/isAdmin`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                logsInfo: {
                    origin: 'SelectClient',
                    sessionID: sessionID
                }
            })
        })
            .then((response) => response.json())
            .then((aux) => {
                setIsAdmin(aux.role === 'ADMIN')
            });
    }, [])

    const goToControl = () => {
        setClient({
            'id': '',
            'name': '',
            'access1': '',
            'access2': '',
            'access3': '',
            'access4': '',
            'access5': '',
            'access6': '',
            'access7': '',
            'access8': '',
            'access9': '',
            'language': 'es',
            'currency': '',
            'numberFormat': '',
        })
        navigate('/control')
    }

    const handleSelectClient = (client) => {
        let auxIndex = client.startIndex
        if (options[auxIndex].idClient === options[auxIndex + 1]?.idClient) {
            let aux = []
            let auxIndex2 = auxIndex
            while (auxIndex2 < options.length && options[auxIndex].idClient === options[auxIndex2].idClient) {
                aux.push(options[auxIndex2])
                auxIndex2++
            }
            console.log(aux, 'This is aux');
            setSelectedClientOptions(aux)
            setSelectedClient(client)
        } else if (options[auxIndex]?.idRS) {
            console.log(options, 'This is options 1')
            optionRSSelect(options[client.startIndex])
        } else {
            console.log(options, 'This is options 2')
            optionSelect(options[client.startIndex])
        }
    }

    return (
        <div className=' py-[5%] px-[140px] h-[100vh] relative bg-light-purple-6'>
            <div className='flex justify-end'>
                <MDCashLogo1 fill={'#1d0039'} />
            </div>
            <div className='absolute left-[50%] top-[50%] bg-white w-[60vw] h-[60vh] -translate-x-[50%] -translate-y-[50%] rounded-[20px] pt-[70px] px-[70px] flex'>
                <div className='w-1/2'>
                    <p className='2xl:text-h2-2xl  2xl:text-[50px] text-[35px] font-bold'>{Idioma.welcomeScreen.texto1[language]}, {userInfo.name.split(' ')[0]}!</p>
                    <p className='2xl:text-lg mt-2 '>{Idioma.welcomeScreen.texto4[language]}</p>
                </div>
                <div className='w-1/2 relative pl-[40px]'>
                    {selectedClient ?
                        <div className='overflow-y-scroll max-h-[70%]'>
                            {selectedClientOptions.map((option) =>
                            (
                                <>
                                    {option.idRS ?
                                        <button onClick={() => optionRSSelect(option)} className={`2xl:text-p5-2xl text-left text-[12px] text-neutral-1 hover:cursor-pointer hover:text-black hover:font-bold  flex justify-start gap-x-[30px] items-center w-[70%]  h-[80px] rounded-button border-b-1 border-gray-5`}>
                                            <span className='w-[30px] '><NavbarUserLogo /></span><p className=''>{option.rsAlias}</p>
                                        </button>
                                        :
                                        null
                                    }
                                </>
                            ))}
                            {selectedClientOptions.map((option) => (
                                <>{!option.idRS && (
                                    <button onClick={() => optionSelect(option)} className={`2xl:text-p5-2xl text-left text-[12px] text-neutral-1 hover:cursor-pointer hover:text-black hover:font-bold  flex justify-start gap-x-[30px] items-center w-[70%] h-[80px] rounded-button border-b-1 border-gray-5`}>
                                        <span className='w-[30px] '><SelectConsolidatedLogo /></span><p className=''>{option.client}</p>
                                    </button>
                                )

                                }
                                </>
                            ))}
                        </div>
                        :
                        <div className='overflow-y-scroll max-h-[70%]'>
                            {firstPageOptions.map((client) => (
                                <>
                                    <button onClick={() => handleSelectClient(client)} className={`2xl:text-p5-2xl text-left text-[12px] text-neutral-1 hover:cursor-pointer hover:text-black hover:font-bold  flex justify-start gap-x-[30px] items-center w-[70%]  h-[80px] rounded-button border-b-1 border-gray-5`}>
                                        <NavbarUserLogo /><p className=''>{client.client}</p>
                                    </button>
                                </>
                            ))}
                        </div>}
                    {isAdmin && <button onClick={() => goToControl()} className='absolute bottom-[40px] text-left flex gap-[30px] items-center text-neutral underline text-p5-2xl'>
                        <div className=' h-[46px] w-[46px] flex justify-center items-center'><ControlCenterLogo fill="#000" /></div>
                        {Idioma.centroDeControl[language]}
                    </button>}
                    <div className='absolute bottom-[40px] flex gap-x-[10px] -left-[40px]'>
                        <button>
                            <DotLogo fill={selectedClient ? '#EDEDED' : '#adadad'} />
                        </button>
                        <button>
                            <DotLogo fill={selectedClient ? '#adadad' : '#EDEDED'} />
                        </button>
                    </div>
                </div>

            </div>

            <button onClick={() => handleLogout(instance)} className='2xl:text-p5-2xl text-[16px] cursor-pointer flex items-center absolute 2xl:bottom-[100px] bottom-[10px] left-[120px] underline'>
                <LogOutLogo fill={'#9191F4'} />
                <span className='ml-4 text-light-purple-3'>
                    {Idioma.sideBar.cerrar[language]}
                </span>
            </button>
            {(browser === 'Safari' || browser === 'Unknown') &&
                <div className='bg-gray-5 absolute  top-[10%] -translate-x-[50%] left-[50%] w-[40%] rounded-main text-gray-3 p-5 flex items-center'>
                    <ChromeLogo />
                    <span className='pl-5'>{Idioma.welcomeScreen.navegador[language]}</span>
                </div>}
            <button onClick={() => window.open("https://md.partners", '_blank').focus()} className='text-light-purple-3 underline absolute 2xl:bottom-[100px] bottom-[20px] right-[140px] 2xl:text-p5-2xl'>
                Powered by MD Partners
            </button>
        </div>
    )
}

export default SelectClientScreen