import { Fragment, useState } from 'react'
import { Transition, Combobox } from '@headlessui/react'
import OpenDropdown from '../assets/OpenDropdown'
import CloseDropdown from '../assets/CloseDropdown'
import CloseLogo from '../assets/CloseLogo'
import Idioma from './Idioma'
import { useStateContext } from '../contexts/ContextProvider'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const DinamicDropdown = ({options, selected, setSelected, className, disabled, percentage=false, combo=false, multi=false, rsIdSelector=false, rsIdLabels=false , tableDropdown=false, placeHolder=''}) => {
  
    const {language} = useStateContext()
    const [query, setQuery] = useState('')

    const optionBgColors = ['bg-light-purple-2', 'bg-light-purple-3', 'bg-light-purple-4', 'bg-purple-2', 'bg-purple-3', 'bg-purple-4']
    const optionTxtColors = ['text-light-purple-6', 'text-light-purple-5', 'text-light-purple-1', 'text-purple-6', 'text-purple-6', 'text-purple-6']
    const optionIconColors = ['#F7F7FF', '#E8E8FF', '#2121BE', '#F3EAFF', '#F3EAFF', '#F3EAFF']

    const filteredOptions =
    query === ''
      ? options
      : options.filter((option) =>
          option.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(query.toLowerCase().replace(/\s+/g, ''))
        )
    const forMultiIsOptionSelected = (option) => {
      for(let i=0;i<selected.length;i++){
        if(option.id===selected[i].id){
          return true
        }
      }
      return false
    }
    

  return (
    <Combobox disabled={disabled} onChange={setSelected} value={selected} multiple={multi} rsIdSelector={rsIdSelector} rsIdLabels={rsIdLabels} tableDropdown={tableDropdown} name="element" as="element">
      {({ open }) => (
        <>
          <div className="relative">
            { combo ? 
            <>
            <Combobox.Input
              className={` ${className} ${rsIdLabels || tableDropdown ? "" : disabled ? "border-1 bg-gray-6 border-gray-5 text-neutral-3/50 focus:outline-none pl-3 pr-6" : "border-1 border-neutral-1 hover:border-light-purple-4 focus:border-light-purple-4 focus:outline-none pl-3 pr-6"} relative cursor-default rounded-button text-left `}
              displayValue={(option) => option.name}
              onChange={(event) => setQuery(event.target.value)}
            />
            <Combobox.Button className=" absolute inset-y-0 right-0 flex items-center pr-2 pl-2 focus:border-light-purple-4">
              {open ? <CloseDropdown/> : <OpenDropdown/>}
            </Combobox.Button>
            </>
            :
            <Combobox.Button className={` ${className} ${tableDropdown ? "" : rsIdLabels ? "overflow-x-scroll perfect-scrollbar" : disabled ? "border-1 bg-gray-6 border-gray-5 text-neutral-3/50 focus:outline-none pl-3 pr-6" : "border-1 border-neutral-1 hover:border-light-purple-4 focus:border-light-purple-4 focus:outline-none pl-3 pr-6"} relative rounded-button text-left cursor-pointer`}>
              <span className={` ${tableDropdown ? "flex grow" : ""} flex items-center list-none`}>
                {(multi && !rsIdSelector && selected.length > 0 ) ? selected.map((option, index) => 
                  rsIdLabels ? 
                  <div className={`flex items-center mx-1 justify-center rounded-full pl-2 h-button-4 ${optionBgColors[index%6]} ${optionTxtColors[index%6]}`}>
                    <span className="block truncate ">{option.name}</span>
                    <Combobox.Option key={option.id} className={"cursor-pointer px-2"} value={option} onClick={(e) => e.stopPropagation()}>
                        <CloseLogo stroke={`${optionIconColors[index%6]}`}/>
                      </Combobox.Option>
                  </div>
                  :
                  <span className="block truncate ">{option.name}{index<selected.length-1 ? ',': null}</span>
                  )
                  : <span className="block truncate">{rsIdSelector ? Idioma.filtros.empresa[language] : selected.name ? selected.name : placeHolder}</span>}
                { percentage && <span className='text-secondary font-bold absolute right-12'>{parseInt(selected.percentage) ? `${parseInt(selected.percentage)}%` : ''}</span>}
              </span>
              {rsIdLabels || disabled ? null 
              : 
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 ml-2">
                {open ? <CloseDropdown/> : <OpenDropdown/>}
              </span>}
            </Combobox.Button> }

            <Transition
              show={rsIdLabels ? false : open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Combobox.Options className="z-[50] w-auto absolute overflow-y-auto overflow-x-hidden  max-h-[170px] rounded-md bg-white shadow-low-elevation">
              {filteredOptions.length === 0 && query !== '' ? 
                <div className="relative cursor-default select-none px-4 py-2">
                  {Idioma.filtros.noExistente[language]}
                </div>
               :
                filteredOptions.map((option) => (
                  <Combobox.Option
                    key={option.id}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-gray-6' : 'bg-white',
                        className + ' !h-button-4 cursor-pointer select-none flex items-center'
                      )
                    }
                    value={option}
                  >

                    {multi ?
                    <> 
                        {forMultiIsOptionSelected(option) ? 
                        <>
                        <div className="flex items-center ">
                        <div className='grid items-center justify-center'><input className=' peer row-start-1 col-start-1 appearance-none w-4 h-4 border ring-transparent border-neutral-3 hover:border-light-purple-3 rounded checked:bg-light-purple-3 checked:border-light-purple-3 forced-colors:appearance-auto ml-3' type="checkbox" checked={true}/>
                        <svg viewBox="0 0 14 14" fill="none" class="invisible peer-checked:visible row-start-1 col-start-1 stroke-white forced-colors:hidden h-[12px] w-[12px] ml-3.5">
                        <path d="M3 8L6 11L11 3.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                      </svg></div>
                          <span
                            className='font-bold mx-3 block whitespace-nowrap'
                          >
                            {option.name}

                          </span>
                        </div>
                      </> :
                      <>
                      <div className="flex items-center ">
                       <div className='grid items-center justify-center'><input className=' peer row-start-1 col-start-1 appearance-none w-4 h-4 border ring-transparent border-neutral-3 hover:border-light-purple-3 rounded checked:bg-light-purple-3 checked:border-light-purple-3 forced-colors:appearance-auto ml-3' type="checkbox" checked={false}/>
                      <svg viewBox="0 0 14 14" fill="none" class="invisible peer-checked:visible row-start-1 col-start-1 stroke-white forced-colors:hidden h-[12px] w-[12px] ml-3.5">
                      <path d="M3 8L6 11L11 3.5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg></div>
                        <span
                          className='font-normal mx-3 block whitespace-nowrap'
                        >
                          {option.name}

                        </span>
                      </div>
                    </>
                      }
                      </>
                    : 
                    <>
                        <div className="flex items-center ">
                          <span
                            className={classNames(selected.name === option.name ? 'font-bold' : 'font-normal', ' mx-3 block whitespace-nowrap')}
                          >
                            {option.name}

                          </span>
                          { percentage && <span className='text-light-purple-3 group-hover:text-white font-bold ml-auto mr-1'>{option.percentage=='Error' || option.percentage==null ? option.percentage : `${parseInt(option.percentage)}%`}</span>}
                        </div>
                      </>
                    }
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </Transition>
          </div>
        </>
      )}
    </Combobox>
  )
}

export default DinamicDropdown