import React, { useState, useRef, useEffect } from 'react';
import IconI from '../assets/iconI';

const InfoI = ({texto = 'No hay texto', popDirection=true, popXDirection='right'}) => {

    const [showText, setShowText] = useState(false);
    const tooltipRef = useRef(null);
    const [tooltipHeight, setTooltipHeight] = useState(0);
    const [width, setWidth] = useState(10);

    // Medir la altura del tooltip y ajustar su posición
    useEffect(() => {
        if (tooltipRef.current) {
            setTooltipHeight(tooltipRef.current.offsetHeight);
        }
    }, [showText]); // Dependencia en showText para recalcular cuando se muestra el tooltip

    useEffect(() => {
        const handleResize = () => {
            let currentScreen = window.innerWidth;
            if(currentScreen <= 1366 && currentScreen >= 1281) {
                setWidth(8);
            }else if(currentScreen <= 1280 && currentScreen >= 1125) {
                setWidth(7);
            }else {
                setWidth(10);
            }
        }

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    return (
        <div className='relative flex overflow-visible'>
            <div
                onMouseEnter={() => setShowText(true)}
                onMouseLeave={() => setShowText(false)}
                className='cursor-pointer relative xs:hidden xxs:hidden'
            >
                <IconI width={width} />
            </div>
            {showText && (
                <div ref={tooltipRef} 
                     className={`absolute w-auto min-w-[255px] rounded-main bg-light-purple-5 text-p10-2xl text-left text-light-purple-2 p-2 flex justify-center items-center shadow-high-elevation z-[100] lg:min-w-[230px] md:min-w-[230px]`}
                     style={{
                         top: popDirection ? `-${tooltipHeight + 10}px` : '20px',
                         [popXDirection === 'left' ? 'right' : 'left']: '0px'
                     }}>
                    {texto}
                </div>
            )}
        </div>
    );
}

export default InfoI;