import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import EditableText from "../components/EditableText";
import { useStateContext } from "../contexts/ContextProvider";
import Idioma from '../components/Idioma';
import moment from 'moment'
import { useIsAuthenticated } from "@azure/msal-react";
import { getToken } from '../fetch';
import { useNavigate } from "react-router-dom";
import { msalConfig, urlBase } from "../authConfig";
import { MinusCircleIcon } from "@heroicons/react/24/solid";

const CheckerSaldos = () => {
    const { idClient, accessToken, language, NumberFormat, sessionID, setToken, idRS } = useStateContext();
    const [headersTable, setHeadersTable] = useState([]);
    const [banksInfo, setBanksInfo] = useState([]);
    const [comparisonTable, setComparisonTable] = useState([]);
    const [cambio, setCambio] = useState(true);
    const [oldestAccount, setOldestAccount] = useState(false)
    const currentDate = new Date();
    const startDateInitial = new Date( new Date().setFullYear(currentDate.getFullYear(), currentDate.getMonth() - 2, 0) );
    const endDateInitial = new Date(new Date().setFullYear(currentDate.getFullYear(), currentDate.getMonth(), 0));
    const [startDate, setStartDate] = useState(startDateInitial);
    const [endDate, setEndDate] = useState(endDateInitial);
    const [reload, setReload] = useState(false)
    const [lastTransactions, setLastTransactions] = useState();
    const table1 = useRef(null);
    const table2 = useRef(null)
    
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();

    const formatDates = (dates) => {
        let dateNow = new Date(dates);

        let day = dateNow.getUTCDate();
        let month = dateNow.getMonth() + 1;
        let year = dateNow.getUTCFullYear();

        return `${month}/${day}/${year}`;
    }

    useEffect(() => {
        if(!isAuthenticated){
            navigate('/')
            return
        }
		getToken().then((token)=>{setToken(token)})
    }, []);

    const cambios = () => {
        setCambio(!cambio);
    }

    const createBalance = () => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');

        let thisMonth = new Date()
        //thisMonth.setUTCMonth(thisMonth.getUTCMonth()+1)
        //thisMonth.setDate(0)

        let body = {
            //clientId: idClient,
            //date_d: `${thisMonth.getUTCFullYear()}${(thisMonth.getUTCMonth()+1) < 10 ? '0' + (thisMonth.getUTCMonth()+1) : thisMonth.getUTCMonth()+1}${thisMonth.getUTCDate() < 10 ? '0' + thisMonth.getUTCDate() : thisMonth.getUTCDate()}`
        }

        fetch(`${msalConfig.auth.clientId === 'a05b95e4-a7bb-415d-a5fa-47f567e5d94c' ? 'https://cico-functions-dev.azurewebsites.net/api/CreateAllBalances' : 'https://cico-functions.azurewebsites.net/api/CreateAllBalances'}`,{
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        }).then(
            getData()
        )
    }

    const getData = async () => {
        let headers = new Headers();
        const bearer = `Bearer ${accessToken}`;
        headers.append('Authorization', bearer);
        headers.append('Content-Type', 'application/json');

        let body = {
            clientID: idClient,
            logsInfo: {
                origin: 'Verificacion de saldos',
                sessionID: sessionID
              }
        }

        fetch(`${urlBase}/getOldestAccount`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        }).then((res) => res.json())
        .then((aux) => {
            setOldestAccount(new Date(aux.initialDate))
        })

        body.startDate = startRealDate
        body.endDate = endRealDate
        body.monthsLenght = monthsLenght

        if(idRS) {
            body.idRS = idRS.toString();
        }else {
            body.idRS = '';
        }

        fetch(`${urlBase}/getAccountsBalance`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(body)
        }).then((res) => res.json())
        .then((aux) => {
            let banksExpected = new Set();
            let uniqueAliases = {};
            let uniqueDates = [];
            let uniqueAliasesComparison = {};
            let comparisonExpected = new Set();

            let maxLength = 0;
            let maxTransactionsObj = null;
            Object.keys(aux.auxAccounts).forEach(key => {
                const obj = aux.auxAccounts[key];
                if(obj && obj.length > maxLength) {
                    maxLength = obj.length;
                    maxTransactionsObj = obj;
                }
            })

            maxTransactionsObj.forEach(key => {
                if(key.date != undefined) {
                    uniqueDates.push(formatDates(key.date))
                }
            })

            Object.keys(aux.auxAccounts).forEach(key => {
                aux.auxAccounts[key].forEach(element => {
                    if (!uniqueAliases[element.account]) {
                        uniqueAliases[element.account] = { bank: element.bank, currency: element.currency, transactions: [], alias: element.alias };
                    }

                    uniqueAliases[element.account].transactions.push({
                        comparisonBalance: element.comparisonBalance,
                        date: element.date,
                        expectedBalance: element.expectedBalance,
                        id: key,
                        user: element.name, 
                        modDate: element.modDate
                    })
                })
            });

            banksExpected = Object.entries(uniqueAliases).map(([account, bank]) => ({
                account, bank
            }))

            /*Object.keys(aux).forEach(key => {
                aux[key].forEach(element => {
                    if (!uniqueAliasesComparison[element.account]) {
                        uniqueAliasesComparison[element.account] = { bank: element.bank, currency: element.currency, transactions: [], alias: element.alias }
                    }

                    uniqueAliasesComparison[element.account].transactions.push({
                        comparisonBalance: element.comparisonBalance,
                        expectedBalance: element.expectedBalance,
                        user: element.name, 
                        modDate: element.modDate
                    })
                })
            })

            comparisonExpected = Object.entries(uniqueAliasesComparison).map(([account, bank]) => ({
                account, bank
            }))

            setComparisonTable(comparisonExpected)*/
            setLastTransactions(aux.lastTransaction);
            setBanksInfo(banksExpected)
            setHeadersTable([...uniqueDates]);
            setReload(true)
        }).catch(err => {
            console.log("Error: ", err);
        });
    };

    useEffect(()=>{
        setReload(false)
    },[banksInfo])

    const getMonthDifference = (start, end) => {
        if (!start || !end) {
            return 0;
        }
        
        const startYear = start.getFullYear();
        const endYear = end.getFullYear();
        const startMonth = start.getMonth();
        const endMonth = end.getMonth();
        
        const yearDifference = endYear - startYear;
        const monthDifference = endMonth - startMonth;
        const totalMonthDifference = yearDifference * 12 + monthDifference;
        
        return totalMonthDifference + 1;
    }

    const formatDatesPicker = ( date ) => {
        let dateNew;

        if(date == null){
            return;
        }

        /*if( date.getUTCMonth() === new Date().getUTCMonth() && date.getUTCFullYear() === new Date().getUTCFullYear() ){
            let date = new Date();
            dateNew = `${date.getUTCFullYear()}${(date.getUTCMonth() + 1) < 10 ? '0' + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1}${date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()}`;
            return dateNew;
        }*/
        date.setHours(0,0,0,0)
        dateNew = `${date.getUTCFullYear()}${(date.getUTCMonth() + 1) < 10 ? '0' + (date.getUTCMonth() + 1) : date.getUTCMonth() + 1}${date.getUTCDate() < 10 ? '0' + date.getUTCDate() : date.getUTCDate()}`;
        return dateNew
    }

    const [startRealDate, setStartRealDate] = useState(formatDatesPicker(startDate));
    const [endRealDate, setEndRealDate] = useState(formatDatesPicker(endDate));
    const [monthsLenght, setMonthsLenght] = useState(getMonthDifference(startDateInitial, endDateInitial));

    const handleChange = (dates) => {
        const [start, end] = dates;

        const adjustedStartDate = start ? new Date(start.getFullYear(), start.getMonth() + 1, 0) : null;
        const adjustedEndDate = end ? new Date(end.getFullYear(), end.getMonth() + 1, 0) : null;

        setMonthsLenght(getMonthDifference(adjustedStartDate, adjustedEndDate))

        setStartRealDate(formatDatesPicker(adjustedStartDate));
        setEndRealDate(formatDatesPicker(adjustedEndDate));

        setStartDate(adjustedStartDate);
        setEndDate(adjustedEndDate);
    }

    function formatDateString(dateStr) {
        {/*let months;

        if(language === 'es'){
            months = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
        }else {
            months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        }
    const [month, day, year] = dateStr.split("/");*/}
        return moment(dateStr).locale(language).format(language==='en' ?'MMM/DD/YYYY':'DD/MMM/YYYY').replace('.','');
    }

    const extraInfo = (date1, date2) => {
        let date  = new Date(date2['date']);
        let year = date.getUTCFullYear();
        let month  = date.getMonth() + 1; 
        if(date1 !== undefined) {
            const foundElement = date1.find(element => element['when'] === `${month}/${year}`)

            if(foundElement) {
                const date = new Date(foundElement['lastChange']);
                const year = date.getUTCFullYear()
                const month = date.getUTCMonth() + 1;
                const day = date.getUTCDate();
                
                return `${foundElement['name']} - ${day}/${month}/${year}`;
            }
        }
        return undefined;
    }

    const titleMessage = (name, date, diff, lastTransaction = language === 'en' ? 'There is no modification in transactions' : 'No hay modificación en transacciones') => {
        let message = ''
        if(name && date){
            message = `${Idioma.verificacionSaldos.ultimaMod[language]}: ${name} ${formatDateString(date)}\n`
        }
        if(diff){
            if(diff==='check'){
                message += Idioma.verificacionSaldos.verificado[language] + '\n'
            }else if(diff==='disabled'){
                message += ''
            }else{
                message += `${Idioma.verificacionSaldos.diferencias[language]}: ${new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Number(diff))} \n`
            }
        }else{
            message = Idioma.verificacionSaldos.ingreseSaldo[language] + '\n'
        }
        message += `${Idioma.verificacionSaldos.lastTransaction[language]}: ${lastTransaction}`
        return message
    }

    const asysncScrollTable1 = () => {
        if( table2.current && table1.current ){
            table2.current.scrollLeft = table1.current.scrollLeft;
        }
    }

    const asysncScrollTable2 = () => {
        if( table1.current && table2.current ){
            table1.current.scrollLeft = table2.current.scrollLeft;
        }
    }

    useEffect(() => {
        if(startRealDate != null && endRealDate != null){
            getData();
            //createBalance()
        }
    }, [cambio, startRealDate, endRealDate]);

    return (
        <div className="pl-[70px] mr-[40px] max-h-17"> 
            <h2 className="text-h6-2xl text-neutral-1 mt-[50px]">{Idioma.verificacionSaldos.saldosReales[language]}</h2>
            <div className="w-[21%] flex items-center mt-[50px]">
                <h2 className="text-h8-2xl mr-5">{Idioma.verificacionSaldos.fechas[language]}</h2>
                <div className="w-[180px]">
                    <DatePicker
                        className="bg-white rounded-main border-1 border-neutral-1 text-neutral-1 p-3"
                        onChange={(dates) => handleChange(dates)}
                        startDate={startDate}
                        endDate={endDate}
                        showMonthYearPicker
                        dateFormat="MM/yyyy"
                        maxDate={new Date().setDate(0)}
                        minDate={oldestAccount}
                        selectsRange
                    />
                </div>
            </div>
            <h2 className="text-h8-2xl text-neutral-1 mt-[65px]">{Idioma.verificacionSaldos.debito[language]}</h2>
            <div className="overflow-x-auto thin-scrollbar 2xl:w-[75%] mt-[15px] mb-[40px] flex ml-3" ref={table1} onScroll={asysncScrollTable1}>
            <table className="sticky left-0 bg-white">
                    <thead>
                        <tr>
                            <th scope="col" className="py-3 text-h11-2xl text-neutral-1 text-left w-[73px]">
                                {Idioma.verificacionSaldos.banco[language]}
                            </th>
                            <th className="w-[87px]"></th>
                            <th scope="col" className="py-3 2xl:text-h11-2xl text-neutral-1 text-left w-[93px]">
                                {Idioma.verificacionSaldos.cuentas[language]}
                            </th>
                            <th scope="col" className="py-3 text-h11-2xl text-neutral-1 text-left w-[93px]">
                                {Idioma.verificacionSaldos.alias[language]}
                            </th>
                            <th scope="col" className="py-3 2xl:text-h11-2xl text-neutral-1 text-left w-[90px]">
                                {Idioma.verificacionSaldos.moneda[language]}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {banksInfo.map(item => (
                            <tr key={item.alias}>
                                <td className="py-3 whitespace-nowrap flex items-center">
                                    <img src={`https://cicofiles.blob.core.windows.net/bankimages/${item.bank.bank}.png`} alt={item.bank.bank} className="h-[21px]"/>
                                </td>
                                <td className="pr-10 text-p9-2xl">{item.bank.bank}</td>
                                <td className="py-4 text-p9-2xl text-neutral-1 whitespace-nowrap text-left pr-10 ">
                                    {item.account}
                                </td>
                                <td className="py-4 text-p9-2xl text-neutral-1 whitespace-nowrap text-left pr-10">
                                    {item.bank.alias}
                                </td>
                                <td className="py-4 text-p9-2xl text-neutral-1 text-left">
                                    {item.bank.currency}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <table className="ml-3 ">
                    <thead>
                        <tr>
                            {headersTable.map(item => (
                                <th scope="col" className="w-[100px] px-6 py-3 text-h11-2xl text-neutral-1" key={item}>
                                    {formatDateString(item)}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {banksInfo.map(item => (
                            <tr key={item.alias}>
                                {item.bank.transactions.map((aux, index) => (
                                    <td className="px-6 py-4" key={index} title={titleMessage(aux.user, aux.modDate, aux.comparisonBalance, extraInfo(lastTransactions[Number(aux.id)], aux))}>
                                        <EditableText tableText initialText={aux.expectedBalance} date={aux.date} id={aux.id} upload={cambios} reload={reload} diff={aux.comparisonBalance}/>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {/*<div className="2xl:w-[75%] h-[1px] bg-neutral-1 border-none mb-10"></div>
            <h2 className="mt-[40px] text-h8-2xl text-neutral-1">{Idioma.verificacionSaldos.diferencias[language]}</h2>
            <div className="overflow-x-auto thin-scrollbar 2xl:w-[75%] mt-[15px] mb-[40px] flex ml-3" ref={table2} onScroll={asysncScrollTable2}>
                <table className="sticky left-0 bg-white">
                    <thead>
                        <tr>
                            <th scope="col" className="py-3 text-h11-2xl text-neutral-1 text-left w-[73px]">
                                {Idioma.verificacionSaldos.banco[language]}
                            </th>
                            <th className="w-[87px]"></th>
                            <th scope="col" className="py-3 text-h11-2xl text-neutral-1 text-left w-[93px]">
                                {Idioma.verificacionSaldos.cuentas[language]}
                            </th>
                            <th scope="col" className="py-3 text-h11-2xl text-neutral-1 text-left w-[93px]">
                                {Idioma.verificacionSaldos.alias[language]}
                            </th>
                            <th scope="col" className="py-3 text-h11-2xl text-neutral-1 text-left w-[90px]">
                                {Idioma.verificacionSaldos.moneda[language]}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {comparisonTable.map(item => (
                            <tr key={item.alias}>
                                <td className="py-3 whitespace-nowrap flex items-center">
                                    <img src={`https://cicofiles.blob.core.windows.net/bankimages/${item.bank.bank}.png`} alt={item.bank.bank} className="h-[21px]"/>
                                </td>
                                <td className="pr-10 text-p9-2xl">{item.bank.bank}</td>
                                <td className="py-4 text-p9-2xl text-neutral-1 whitespace-nowrap pr-10 text-left">
                                    {item.account}
                                </td>
                                <td className="py-4 text-p9-2xl text-neutral-1 whitespace-nowrap text-left pr-10">
                                    {item.bank.alias}
                                </td>
                                <td className="py-4 text-p9-2xl text-neutral-1 whitespace-nowrap text-left">
                                    {item.bank.currency}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <table className="ml-3">
                    <thead>
                        <tr>
                            {headersTable.map(item => (
                                <th scope="col" className="w-[100px] px-6 py-3 text-h11-2xl text-neutral-1" key={item}>
                                    {formatDateString(item)}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {comparisonTable.map(item => (
                            <tr key={item.alias}>
                                {item.bank.transactions.map((aux, index) => (
                                    aux.expectedBalance === 'disabled' ? 
                                    <td className={`px-4 py-2 m-3 w-[85px] h-[28px]`} key={index}>
                                        <div className="bg-gray-6 rounded-main w-full h-full">

                                        </div>
                                    </td>
                                    :
                                    <td className='px-4 py-2 m-3 w-[85px] h-[28px]' key={index}>
                                        <div className={`w-full h-full rounded-main flex justify-center items-center ${aux.comparisonBalance === 'check' ? 'bg-green-3 text-green-1 2xl:text-p9-2xl' : aux.comparisonBalance < 0 || aux.comparisonBalance > 0 ? 'bg-red-4 text-red-1 2xl:text-p9-2xl' : 'bg-gray-6'}`}>
                                            {formatterInfo(aux.comparisonBalance)}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>*/}
        </div>
    );
}

export default CheckerSaldos;
